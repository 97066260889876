import React from "react";
import styled, { keyframes } from "styled-components";

const Loading = () => {
  return (
    <Wrapper>
      <div className="loader"></div>
    </Wrapper>
  );
};

export default Loading;

const spin = keyframes`
from {
  transform: rotate(0deg);
}

to {
  transform: rotate(360deg);
}
`;

const Wrapper = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .loader {
    border: 12px solid #f3f3f3;
    border-radius: 50%;
    border-top: 12px solid #009768;
    width: 60px;
    height: 60px;
    animation: ${spin} 2s linear infinite;
    display: inline-block;
  }
`;
