import React, { lazy, Suspense } from "react";
import {
  Switch,
  Router,
  useLocation,
  Route,
  BrowserRouter,
} from "react-router-dom";
import { ResetCSS, Box } from "@raca2022/uikit";
import Loading from "../components/Loading/LoadingDesktop";

import history from "../routerHistory";
import GlobalStyle from "../style";
import "aos/dist/aos.css";

//
const Home = lazy(() => import("./Home"));

const App = () => {
  return (
    <>
      <Suspense fallback={<Loading />}>
        <Router history={history}>
          {/* @ts-ignore */}
          <ResetCSS />
          {/* @ts-ignore */}
          <GlobalStyle />
            <Switch>
              <Route path="/" exact>
                <Home />
              </Route>
            </Switch>
        </Router>
      </Suspense>
    </>
  );
};

export default App;
